@charset "UTF-8";

/// Provides a single source of truth for styling container elements,
/// as well as providing standard defaults.
///
/// @argument {number (with unit)} $container-width [1440px]
///   Max-width to set on the element
///
/// @argument {list} $margin [0 auto]
///   List of margin values, accepts CSS shorthand
///
/// @argument {list} $padding [0 40px]
///   List of padding values, accepts CSS shorthand
///
/// @example scss
///   .container {
///     @include container();
///   }
///
///   // CSS Output
///   .container {
///     max-width: 1440px;
///     margin: 0 auto;
///     padding: 0 40px;
///   }
///
/// @example scss
///   .container {
///     @include container($padding: 0 15px 2em);
///   }
///
///   // CSS Output
///   .container {
///     max-width: 1440px;
///     margin: 0 auto;
///     padding: 0 15px 2em;
///   }

@mixin container($container-width: 1440px, $margin: 0 auto, $padding: 0 20px) {
  max-width: $container-width;
  margin: $margin;
  padding: $padding;
}
