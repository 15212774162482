@import 'styles/resources';

.home {
  // Animation will trigger while this element is on screen.
  .trigger {
    @include position(absolute, 10vh null null);
    @include size(10px, 275vh);
    //border: 10px solid red;
  }
  // Controls the length of scrolling.
  .content-height {
    @include size(0, 310vh);
    //border: 5px solid blue;
  }
  // Content is positioned absoute so we can control layering.
  .content {
    @include position(absolute, 0 null null);
    @include size(100%, 310vh);
    z-index: $z3;
    &.landscape {
      z-index: $z1;
    }
  }

  .sticky {
    @include container(1920px, $padding: 0);
    @include flex(align-center justify-center);
    @include size(100%, 100vh);
    position: sticky;
    top: 0;
  }

  .splash {
    @include flex(align-center justify-center);
    @include size(100%, calc(100vh - 98px));
    @include position(absolute, 98px null null);
    @include media('>=tablet') {
      height: calc(100vh - 132px);
      top: 132px;
    }
    .logo {
      @include position(absolute, null null calc(46% * 0.75));
      max-width: 75%;
      @include media('>=tablet') {
        max-height: 75%;
        width: 740px;
        position: unset;
        transform: translateY(-30px);
      }
    }
  }

  .intro {
    @include flex(column align-center justify-around);
    @include size(100%);
    max-height: calc(100vh - 98px);
    padding-bottom: 20%;
    position: relative;
    top: 98px;
    opacity: 0;
    @include media('>=tablet') {
      @include flex(column align-center justify-end);
      @include position(absolute, 132px 0 0 0);
      @include size(100%);
      max-height: calc(100vh - 132px);
      padding-bottom: 12%;
    }
    .text {
      max-width: 460px;
      margin: 0 8%;
      text-align: center;
      @include media('>=tablet') {
        margin: 0 22% 20px 21%;
        max-width: 800px;
      }
      h1,
      h3,
      p {
        color: $white;
        @include media('>=tablet') {
          color: $thunder;
        }
      }
      h1 {
        margin-bottom: 1rem;
        text-transform: uppercase;
      }
      h3 {
        margin-bottom: 0.5rem;
      }
      p {
        @include fontstyle(13, 22, normal, 400);
        margin-bottom: 1rem;
        @include media('>=tablet') {
          @include fontstyle(16, 26, normal, 500);
        }
        @include media('>=wide') {
          @include fontstyle(19, 32, normal, 700);
        }
      }
    }
    .badge-banner {
      @include size(96%, auto);
      max-width: 460px;
      max-height: 280px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 50% 50%;
      gap: 10px;
      align-items: center;
      justify-items: center;
      padding: 10px 40px;
      aspect-ratio: 250 / 150;
      background-image: url(/images/badge-tray-mobile.svg);
      background-position: center;
      @include media('>=tablet') {
        @include flex(align-center justify-between);
        @include size(1400px, auto);
        aspect-ratio: 1384 / 163;
        background-image: url(/images/badge-tray.svg);
        padding: 0 6% 0 3.5%;
        max-width: 70%;
        transform: translateX(25px);
      }
      .badge {
        position: relative;
        max-width: 20vw;
        max-height: 100%;
        &:nth-child(1) {
          @include media('>=tablet') {
            transform: translateY(-20%);
          }
        }
        &:nth-child(2) {
          transform: translateY(20%);
        }
        &:nth-child(3) {
          @include media('>=tablet') {
            transform: translateY(-20%);
          }
        }
        &:nth-child(4) {
          transform: translateY(-20%);
          @include media('>=tablet') {
            transform: translateY(20%);
          }
        }
        &:nth-child(5) {
          transform: translateY(20%);
          @include media('>=tablet') {
            transform: translateY(-20%);
          }
        }
        &:nth-child(6) {
          transform: translateY(-20%);
          @include media('>=tablet') {
            transform: translateY(20%);
          }
        }
      }
    }
  }

  .landscape {
    .desktop {
      display: none;
      @include media('>=tablet') {
        @include position(absolute, null null 0);
        @include size(100%, auto);
        aspect-ratio: 1920 / 1080;
        max-height: 100vh;
        overflow: visible;
        display: block;
      }
    }
    .mobile {
      @include position(absolute, null null 0);
      @include size(100%, auto);
      aspect-ratio: 360 / 480;
      max-height: calc(100% - 98px);
      overflow: visible;
      @include media('>=tablet') {
        display: none;
      }
    }
  }
}
