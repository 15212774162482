@import 'styles/resources';

.footer {
  @include flex(column align-center);
  position: relative;
  padding: 0 0 8px;
  overflow: hidden;
  @include media('>=tablet') {
    padding: 0 0 43px;
  }

  .content {
    @include flex(column align-center);
    margin: 0 30px;
    max-width: 748px;
    z-index: $z3;
  }

  p {
    @include fontstyle(19, 32, normal, 700);
    margin: 0 0 24px;
    text-align: center;
  }
  .logo {
    @include size(348px, auto);
    margin-top: 80px;
    max-width: 25%;
    @include media('>=tablet') {
      margin-top: 100px;
      max-width: 60%;
    }
  }
  .snow-hills {
    @include position(absolute, null null 0);
  }
}
