@import 'styles/resources';

#hamburger {
  @include size(50px);
  line-height: 0;
  cursor: pointer;

  &.is-active {
    svg {
      .circle {
        stroke-width: 1px;
        fill: rgba($thunder, 0);
      }
      .lines {
        fill: $thunder;
      }
      #bar-1 {
        transform: translate3d(8px, 14px, 0) rotate(-225deg);
        transition-delay: 0s;
      }
      #bar-2 {
        opacity: 0;
        transform: translate3d(12.5px, 12.5px, 0) rotate(-180deg);
      }
      #bar-3 {
        transform: translate3d(16px, 14px, 0) rotate(-135deg);
        transition-delay: 0.15s;
      }
    }
  }

  svg {
    @include position(absolute, 0);
    .circle {
      stroke-width: 0;
      stroke: $thunder;
      fill: $thunder;
      transition: fill 0.5s, stroke 0.5s, stroke-width 0.5s;
    }
    .lines {
      fill: $white;
      transform-origin: 12.5px 6.5px;
      transition: fill 0.5s, transform 0.5s, opacity 0.5s;
    }
    #bar-1 {
      transform: translate3d(12.5px, 18px, 0) rotate(0deg);
      transition-delay: 0.15s;
    }
    #bar-2 {
      transform: translate3d(12.5px, 25px, 0) rotate(0deg);
      transition-delay: 0.075s;
    }
    #bar-3 {
      transform: translate3d(12.5px, 32px, 0) rotate(0deg);
      transition-delay: 0s;
    }
  }
}
