@import 'styles/resources';

.social-share {
  @include flex();
  gap: 36px;

  a {
    width: 28px;
  }

  svg {
    fill: $thunder;
  }
}
