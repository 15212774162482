@import 'styles/resources';

.tiktok-modal {
  @include flex(align-center justify-center);
  @include position(fixed, 0 0 0 0);
  z-index: $z9;
  padding: 9px;
  transition: opacity 300ms;
  opacity: 0;
  isolation: isolate;
  pointer-events: none;
  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  .close-button {
    @include position(absolute, 6px 8px null null);
  }

  .inner {
    position: relative;
    width: 420px;
    max-width: 100%;
    padding: 60px 0;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  }

  .overlay {
    @include position(absolute, 0 0 0 0);
    content: '';
    display: block;
    background-color: #333;
    z-index: -1;
    opacity: 0.9;
  }

  .tiktok {
    @include flex(column align-center);
    @include size(320px, 574px);
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    border-radius: 8px;
    background-color: $thunder;
    iframe {
      @include size(325px, 738px);
      position: absolute;
      border: 0;
      z-index: $z1;
    }
    .pulse-loader {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
