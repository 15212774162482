@import 'styles/resources';

.snowy-box {
  background-color: $alto;
  border-radius: 20px;
  @include media('>=tablet') {
    border-radius: 40px;
  }
  .snow {
    display: none;
    @include media('>=tablet') {
      display: block;
      background-color: $white;
    }

    // Left side snow.
    &.left-bottom {
      @include size(21px, 142px);
      @include position(absolute, auto auto 50px -10px);
      mask-image: url(/images/snow-left-bottom.svg);
    }
    &.left-middle {
      @include size(21px, 188px);
      @include position(absolute, 80px auto auto -11px);
      mask-image: url(/images/snow-left-middle.svg);
    }
    &.left-top {
      @include size(78px, 178px);
      @include position(absolute, -20px auto auto -20px);
      mask-image: url(/images/snow-left-top.svg);
    }
    &.left-top-bigger {
      @include size(90px, 204px);
      @include position(absolute, -20px auto auto -24px);
      mask-image: url(/images/snow-left-top.svg);
    }
    &.left-top-corner {
      @include size(304px, 172px);
      @include position(absolute, -45px auto auto -20px);
      mask-image: url(/images/snow-left-top-corner.svg);
    }

    // Right side snow.
    &.right-top-corner {
      @include size(302px, 170px);
      @include position(absolute, -45px -14px auto auto);
      mask-image: url(/images/snow-right-top-corner.svg);
    }
    &.right-bottom {
      @include size(21px, 142px);
      @include position(absolute, auto -11px 50px auto);
      mask-image: url(/images/snow-left-bottom.svg);
      transform: scale(-1, 1);
    }
    &.right-bottom-corner {
      @include size(47px, 113px);
      @include position(absolute, auto -10px -35px auto);
      mask-image: url(/images/snow-right-bottom-corner.svg);
    }

    // Top snow.
    &.top-middle-left {
      @include size(233px, 55px);
      @include position(absolute, -20px auto auto 34%);
      mask-image: url(/images/snow-top-middle-left.svg);
    }
    &.top-middle-left-wide {
      @include size(306px, 72px);
      @include position(absolute, -25px auto auto 41%);
      mask-image: url(/images/snow-top-middle-left.svg);
    }
    &.top-middle-right {
      @include size(203px, 38px);
      @include position(absolute, -13px 14% auto auto);
      mask-image: url(/images/snow-top-middle-right.svg);
    }
    &.top-middle-right2 {
      @include size(203px, 38px);
      @include position(absolute, -13px auto auto 44%);
      mask-image: url(/images/snow-top-middle-right.svg);
    }
  }
}
