@import 'styles/resources';

// https://cssloaders.github.io
.pulse-loader {
  @include size(48px);
  position: relative;
  &:after,
  &:before {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    content: '';
    border-radius: 50%;
    background-color: $white;
    animation: animloader 2s linear infinite;
  }
  &:after {
    animation-delay: 1s;
  }

  @keyframes animloader {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
