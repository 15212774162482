@import 'styles/resources';

header {
  position: fixed;
  z-index: $z7;

  #logo {
    @include position(fixed, 50px null null 16px);
    @include media('>=tablet') {
      left: 50px;
    }
  }

  #text-logo {
    @include position(fixed, 130px null null 28px);
    display: none;
    transform: translate3d(-300px, 0, 0);
    transition: all 0.3s;
    transition-delay: 0s;
    @include media('>=tablet') {
      display: block;
    }
    &.is-active {
      transform: translate3d(0, 0, 0);
      transition-delay: 0.7s;
    }
  }

  #hamburger {
    @include position(fixed, 50px 16px null null);
    @include media('>=tablet') {
      right: 50px;
    }
  }
}
