@import 'styles/resources';

.video-course {
  margin: 0 auto;
  position: relative;
  @include media('>=tablet') {
    max-width: 1504px;
    margin-bottom: 5%;
  }
  &.right {
    .content {
      flex-direction: row-reverse;
    }
  }

  .content {
    @include flex();
    position: relative;
    z-index: $z3;
    @include media('>=tablet') {
      margin: 0 8% 0 8%;
      gap: 4vw;
    }
    @include media('>=tabtop') {
      margin: 0 13% 0 17%;
    }
  }

  .video {
    @include flex(align-center);
    flex-basis: 43%;
    @include media('>=tablet') {
      flex-basis: 35%;
    }
    .thumbnail {
      @include flex(align-center justify-center);
      position: relative;
      background-color: $ironside-gray;
      filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));
      cursor: pointer;
      &:hover:not(.locked) {
        &:before {
          transform: scale(1.2);
        }
        img {
          opacity: 0.8;
          filter: brightness(1.2) saturate(1.2);
        }
      }
      &:before {
        @include position(absolute, null);
        @include size(28%, auto);
        content: '';
        background-image: url(/images/play-button.svg);
        aspect-ratio: 1;
        z-index: 1;
        transition: transform $animation-time $animation-ease;
      }
      img {
        display: block;
        transition: all $animation-time $animation-ease;
      }
    }
  }

  .info {
    @include flex(column-reverse align-center justify-end);
    flex-basis: 57%;
    padding: 20px 14px 20px 15px;
    position: relative;
    @include media('>=tablet') {
      flex-direction: row;
      flex-basis: 65%;
      justify-content: space-between;
      gap: 20px;
      margin: 4.5vw 0;
      padding: 2vw 0;
    }
    .copy {
      margin: 73px 0 20px;
      color: $thunder;
      @include media('>=tablet') {
        margin: 0;
      }
      h2 {
        margin-bottom: 10px;
        @include media('>=tablet') {
          margin-bottom: 24px;
        }
      }
      p {
        @include fontstyle(11, 17, normal, 700);
        @include media('>=tablet') {
          @include fontstyle(16, 32, normal, 700);
        }
      }
    }
  }

  .badge-wrapper {
    @include media('>=tablet') {
      @include flex(align-end);
      height: 100%;
    }
  }
  .badge-1 {
    transform: translate(-50%, -20px);
    @include media('>=tablet') {
      transform: unset;
    }
  }
  .badge-2 {
    transform: translate(-50%, -37px);
    @include media('>=tablet') {
      transform: unset;
    }
  }
  .badge-3 {
    transform: translate(-50%, -31px);
    @include media('>=tablet') {
      transform: unset;
    }
  }
  .badge-4 {
    transform: translate(-50%, -36px);
    @include media('>=tablet') {
      transform: unset;
    }
  }
  .badge-5 {
    transform: translate(-50%, -35px);
    @include media('>=tablet') {
      transform: unset;
    }
  }
  .badge-6 {
    transform: translate(-50%, -18px);
    @include media('>=tablet') {
      transform: unset;
    }
  }

  .snowy-box {
    @include position(absolute, 20px 0);
    @include media('>=tablet') {
      @include position(absolute, 4.5vw 4% 4.5vw 4%);
    }
    @include media('>=tabtop') {
      @include position(absolute, 4.5vw 9% 4.5vw 12%);
    }
  }

  &.locked {
    .video {
      pointer-events: none;
      .thumbnail {
        &:before {
          display: none;
        }
      }
    }
  }
}
