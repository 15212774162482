@import 'styles/resources';

#menu-drawer {
  @include position(fixed, 0 0 0 0);
  @include flex(column justify-between);
  z-index: $z6;
  padding-top: $header-height;
  overflow: auto;
  background: $westar;
  transform: translate3d(100%, 0, 0);
  transition: all 0.5s ease-out;
  @include media('>=tablet') {
    @include flex(row wrap);
    padding-top: $header-height * 1.25;
    transition-delay: 0.2s;
  }
  @include media('>=desktop') {
    padding-top: $header-height;
    padding-right: 7%;
    padding-left: 20%;
    transition-delay: 0s;
  }
  &.is-active {
    transform: translate3d(0, 0, 0);
  }

  .nav-section {
    width: 100%;
  }

  .links-wrapper {
    flex: 1 1 99%;
    margin-top: 2em;
    @include media('>=tablet') {
      flex: 0 0 50%;
      order: 1;
      margin: 0;
    }
    @include media('>=desktop') {
      flex: 0 0 60%;
    }

    .links {
      @include flex(wrap);
      margin: 0 1em;
      .link {
        flex: 1 1 100%;
        margin: 0.375em 0 0.25em;
        line-height: 0.7em;
        user-select: none;
        @include media('>=tablet') {
          margin: 0.375em 0 0.35em;
          font-size: 3em;
        }
        @include media('>=desktop') {
          font-size: $h0;
        }
        a {
          text-decoration: none;
          transition: all 0.3s ease-out;

          &:hover {
            color: $hollywoodcerise;
            @include media('>=desktop') {
              padding-left: 0.5em;
            }
          }
        }
      }
    }
  }

  .social-wrapper {
    margin-top: 2em;
    margin-bottom: 2em;
    @include media('>=tablet') {
      flex: 0 0 100%;
      align-self: flex-end;
      order: 3;
      margin-top: 0;
      margin-bottom: 2em;
      padding: 0 1em;
    }
    @include media('>=desktop') {
      flex: 0 0 60%;
      align-self: auto;
      margin-bottom: 4em;
      padding: 0;
    }

    .links {
      @include flex(align-center justify-around);
      width: 100%;
      @include media('>=tablet') {
        justify-content: center;
        padding: 0 1em;
      }
      @include media('>=desktop') {
        justify-content: flex-start;
      }

      .link {
        line-height: 0;
        cursor: pointer;
        user-select: none;
        @include media('>=tablet') {
          margin: 0 1.5em;
        }
        @include media('>=desktop') {
          margin: 0 2.5em 0 0;
        }
        &:hover {
          svg {
            fill: $hollywoodcerise;
          }
        }

        a {
          display: block;
        }

        svg {
          height: 1.25em;
          fill: $thunder;
          transition: fill 0.3s ease-out;
          @include media('>=tablet') {
            height: 1.4em;
          }
        }
      }
    }
  }

  .logo-wrapper {
    display: none;
    flex: 0 0 100%;
    order: 4;
    margin-top: 1em;
    padding-bottom: 4em;
    user-select: none;
    @include media('>=tablet') {
      @include flex(align-center justify-center);
      align-self: flex-start;
      margin: 0;
      padding: 0;
    }
    @include media('>=desktop') {
      flex: 0 0 40%;
      align-items: flex-start;
      align-self: auto;
      justify-content: flex-end;
      margin-top: -1em;
      padding: 0;
    }

    .bracket-text {
      color: $thunder;
      font-family: $heading-font;
      font-size: 1.2em;
      font-weight: bold;
      line-height: 1.15em;
      position: relative;
      padding: 0.5em 1.5em;
      &:before,
      &:after {
        @include position(absolute, 0 null null null);
        @include size(0.75em, 100%);
        border-color: $white;
        content: '';
        border: 2px solid $white;
      }
      &:before {
        border-right: none;
        left: 0;
      }
      &:after {
        border-left: none;
        right: 0;
      }
    }
  }
}
