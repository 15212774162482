@import 'styles/resources';

.close-button {
  @include flex(align-center justify-center);
  @include size(30px);
  cursor: pointer;
  svg {
    @include size(28px);
    fill: $thunder;
  }
}
