@import 'styles/resources';

.badge {
  position: absolute;
  transform: translateY(-15px);
  @include media('>=tablet') {
    @include flex(align-end);
    height: 100%;
    position: relative;
  }
  &:before,
  &:after {
    @include size(100%);
    content: '';
    display: block;
    background-size: contain;
  }
  &:before {
    position: absolute;
    z-index: $z1;
    backface-visibility: visible;
    transform-style: preserve-3d;
    opacity: 0;
    transition: opacity 400ms;
  }
  &.is-complete:before {
    animation: flip 2s ease;
    opacity: 1;
  }

  @keyframes flip {
    0% {
      transform: scale(0.6) rotateY(0deg);
    }
    60% {
      transform: scale(1.75) rotateY(360deg);
    }
    100% {
      transform: scale(1) rotateY(720deg);
    }
  }

  &.badge-1 {
    @include size(113px, 81px);
    aspect-ratio: 182 / 131;
    @include media('>=tablet') {
      @include size(182px, 131px);
    }
    &:before {
      background-image: url(/images/badge1.svg);
    }
    &:after {
      background-image: url(/images/badge1-outline.svg);
    }
  }

  &.badge-2 {
    @include size(77px, 103px);
    aspect-ratio: 124 / 165;
    @include media('>=tablet') {
      @include size(124px, 165px);
    }
    &:before {
      background-image: url(/images/badge2.svg);
    }
    &:after {
      background-image: url(/images/badge2-outline.svg);
    }
  }

  &.badge-3 {
    @include size(96px);
    aspect-ratio: 1;
    @include media('>=tablet') {
      @include size(158px);
    }
    &:before {
      background-image: url(/images/badge3.svg);
    }
    &:after {
      background-image: url(/images/badge3-outline.svg);
    }
  }

  &.badge-4 {
    @include size(80px, 101px);
    aspect-ratio: 123 / 156;
    @include media('>=tablet') {
      @include size(123px, 156px);
    }
    &:before {
      background-image: url(/images/badge4.svg);
    }
    &:after {
      background-image: url(/images/badge4-outline.svg);
    }
  }

  &.badge-5 {
    @include size(79px, 101px);
    aspect-ratio: 119 / 152;
    @include media('>=tablet') {
      @include size(119px, 152px);
    }
    &:before {
      background-image: url(/images/badge5.svg);
    }
    &:after {
      background-image: url(/images/badge5-outline.svg);
    }
  }

  &.badge-6 {
    @include size(110px, 75px);
    aspect-ratio: 167 / 114;
    @include media('>=tablet') {
      @include size(167px, 114px);
    }
    &:before {
      background-image: url(/images/badge6.svg);
    }
    &:after {
      background-image: url(/images/badge6-outline.svg);
    }
  }
}
