/**
 * color map
 * names determined using tool located at: http://chir.ag/projects/name-that-color
 */
$alto: #d9d9d9;
$black: #000;
$cerulean: #00a1e4;
$geyser: #d9e0e2;
$hollywoodcerise: #ec008c;
$ironside-gray: #686766;
$pampas: #efece4;
$thunder: #3c3539;
$mystic: #edf2f4;
$westar: #e3e1da;
$white: #fff;

/* font settings */
//$primary-font: 'Helvetica Now';
$heading-font: 'aileron', sans-serif;
$body-font: 'Lora', serif;
$p: 1.25rem;
$p-mobile: 1rem;
$h0: 3.75rem;
$h0-mobile: 2.5rem;
$h1: 3.25rem;
$h1-mobile: 1.875rem;
$h2: 1.875rem;
$h2-mobile: 1.5rem;
$h3: 1.625rem;
$h3-mobile: 1.25rem;
$h4: 1.25rem;
$h5: 1rem;
$h5-mobile: 0.75rem;
$h6: 0.875rem;
$small: 0.625rem;

/* properties */
$header-height: 150px;

/* z-index levels */
$z0: 0;
$z1: 9;
$z2: 99;
$z3: 999;
$z4: 9999;
$z5: 99999;
$z6: 999999;
$z7: 9999999;
$z8: 99999999;
$z9: 999999999;

/* responsiveness */
$breakpoints: (
  tablet: 768px,
  tabtop: 1024px,
  desktop: 1200px,
  wide: 1440px,
);

/* animation */
$animation-time: 300ms;
$animation-ease: cubic-bezier(0.64, 0.04, 0.35, 1);
