/* =Typography
----------------------------------------------- */

// pretty up everything
div,
p,
small,
h1,
h2,
h3,
h4,
h5,
h6,
li,
button,
svg text,
input,
textarea,
select {
  color: $thunder;
  font-family: $heading-font, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.h0 {
  font-size: $h0-mobile;
  font-weight: bold;
  line-height: 1.2em;

  @include media('>=tablet') {
    font-size: $h0;
  }
}

div {
  line-height: 1.15em;
}
p,
li {
  @include fontstyle(18, 23);
}
h1 {
  @include fontstyle(29, 34, normal, 900);
  @include media('>=tablet') {
    font-size: 4vw;
    line-height: 1em;
  }
  @include media('>=wide') {
    @include fontstyle(72, 86, normal, 900);
  }
}
h2 {
  @include fontstyle(20, 24, normal, 900);
  @include media('>=tablet') {
    @include fontstyle(41, 49, normal, 900);
  }
}
h3 {
  @include fontstyle(16, 24, normal, 900);
  @include media('>=tablet') {
    font-size: 1.6vw;
    line-height: 1em;
  }
  @include media('>=wide') {
    @include fontstyle(24, 32, normal, 900);
  }
}
h4 {
  @include fontstyle(22, 28);
}
h5 {
  @include fontstyle(16, 22);
}
h6 {
  @include fontstyle(16, 17);
}
small {
  @include fontstyle(14, 19);
}

/* set up the core element typography */

p,
h1,
h2,
h3,
h4,
small {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: underline;

  &:active {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
}

ul {
  margin: 0;
  padding-left: 1.25em;

  li {
    margin-bottom: 0.5em;
  }
}

// Standard typography classes
.typo-form-header {
  @include fontstyle(13, normal, normal, 700);
}

// text styling helper classes
.w-light {
  font-weight: 300;
}
.w-book {
  font-weight: 400;
}
.w-medium {
  font-weight: 500;
}
.w-bold {
  font-weight: 600;
}
.w-xbold {
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
