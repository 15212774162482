@import 'styles/resources';

#logo {
  @include size(50px);
  line-height: 0;

  a {
    @include position(absolute, 0);
    z-index: 1;
    text-indent: -99em;
  }

  svg {
    @include position(absolute, 0);
    fill: $thunder;
    transition-delay: 0s;
  }
}
