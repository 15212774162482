@import 'styles/resources';

.divider {
  position: relative;
  max-width: 1504px;
  margin: 0 auto;
  width: 100%;
  @include media('>=tablet') {
    height: 0;
  }
  &:before {
    content: '';
    display: block;
    background-size: contain;
    @include media('>=tablet') {
      z-index: $z1;
    }
  }
  &.position-1 {
    // 309px is 100% width.
    aspect-ratio: 309 / 61;
    @include media('>=tablet') {
      transform: translateX(-7%);
    }
    @include media('>=tabtop') {
      transform: unset;
    }
    &:before {
      @include position(absolute, null null 0 8px);
      aspect-ratio: 294 / 72;
      background-image: url(/images/divider1-mobile.svg);
      width: 97%;
      @include media('>=tablet') {
        position: unset;
        aspect-ratio: 1322 / 456;
        background-image: url(/images/divider1.svg);
        transform: translate(5%, -90%);
        width: 81%;
      }
    }
  }
  &.position-2 {
    @include flex(align-center justify-center);
    aspect-ratio: 309 / 69;
    &:before {
      aspect-ratio: 268 / 48;
      background-image: url(/images/divider2-mobile.svg);
      width: 97%;
      @include media('>=tablet') {
        aspect-ratio: 1093 / 168;
        background-image: url(/images/divider2.svg);
        transform: translate(8%, -17%);
        width: 74%;
        rotate: -4.5deg;
      }
    }
  }
  &.position-3 {
    @include flex(align-center justify-center);
    aspect-ratio: 309 / 59;
    &:before {
      position: absolute;
      aspect-ratio: 282 / 66;
      background-image: url(/images/divider3-mobile.svg);
      width: 97%;
      @include media('>=tablet') {
        aspect-ratio: 925 / 215;
        background-image: url(/images/divider3.svg);
        transform: translate(3%, -10%);
        width: 65%;
      }
    }
  }
  &.position-4 {
    aspect-ratio: 309 / 71;
    &:before {
      @include position(absolute, -14% null null null);
      aspect-ratio: 298 / 78;
      background-image: url(/images/divider4-mobile.svg);
      width: 97%;
      @include media('>=tablet') {
        aspect-ratio: 846 / 177;
        background-image: url(/images/divider4.svg);
        transform: translate(37%, -70%);
        width: 60%;
      }
    }
  }
  &.position-5 {
    aspect-ratio: 309 / 59;
    &:before {
      aspect-ratio: 292 / 52;
      background-image: url(/images/divider5-mobile.svg);
      width: 97%;
      @include media('>=tablet') {
        aspect-ratio: 818 / 145;
        background-image: url(/images/divider5.svg);
        transform: translate(46%, -73%);
        width: 57%;
      }
    }
  }
}
