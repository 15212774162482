html,
body {
  @include size(100%);
  margin: 0;
}

html {
  box-sizing: border-box;
}

// lock in images to the viewport width site-wide
img {
  max-width: 100%;
  height: auto;
}

*,
::before,
::after {
  box-sizing: inherit;
  background-repeat: no-repeat;
}

#root {
  @include size(100%);
}

.app-wrap {
  @include size(100%);
  position: relative;
}
