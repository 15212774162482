@import 'styles/resources';

.champion {
  padding-bottom: 80px;
  @include media('>=tablet') {
    padding-bottom: 200px;
  }
  &.is-active {
    &.is-complete {
      .image {
        animation-delay: 0ms;
        .overlay {
          transition-delay: 0ms !important;
        }
      }
    }
    .image {
      animation: spin 2500ms ease;
      animation-delay: 4000ms;
      backface-visibility: visible;
      transform-style: preserve-3d;
    }
    .overlay {
      opacity: 0;
    }
  }

  @keyframes spin {
    0% {
      transform: scale(1) rotateY(0deg);
    }
    10% {
      transform: scale(0.6) rotateY(0deg);
    }
    60% {
      transform: scale(1.5) rotateY(360deg);
    }
    100% {
      transform: scale(1) rotateY(720deg);
    }
  }

  .content {
    position: relative;
    text-align: center;
    margin-bottom: 3em;
    z-index: $z3;
    @include media('>=tablet') {
      margin-bottom: 4em;
    }
  }
  h2 {
    @include fontstyle(22, 26, normal, 900);
    text-transform: uppercase;
    margin-bottom: 0.5em;
    @include media('>=tablet') {
      @include fontstyle(52, 62, normal, 900);
    }
  }
  .graphics {
    @include flex(column align-center);
    position: relative;
    padding-top: 101px;
    .award {
      @include flex(column align-center);
      @include position(absolute, 0);
      z-index: $z3;
      .image {
        position: relative;
        aspect-ratio: 821 / 744;
        background-image: url(/images/badge-champion.svg);
        height: 100%;
        max-height: calc(100% - 47px);
        .overlay {
          aspect-ratio: 821 / 744;
          background-image: url(/images/badge-champion-overlay.svg);
          height: 100%;
          transition: opacity $animation-time $animation-ease 4000ms;
        }
      }
      .shadow {
        @include position(absolute, null null 24px 50%);
        @include size(38%, auto);
        aspect-ratio: 550 / 135;
        background: $thunder;
        border-radius: 50%;
        opacity: 0.3;
        mix-blend-mode: multiply;
        transform: translatex(-50%);
        z-index: -1;
      }
    }
  }
}
