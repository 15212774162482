@import 'styles/resources';

.courses {
  padding: 35px 25px 100px;
  @include media('>=tablet') {
    padding: 296px 0;
  }
  > :nth-last-child(2) {
    margin-bottom: 0;
  }
}
