@import 'styles/resources';

/*
.snow-falling {
  @include position(fixed, 0);
  pointer-events: none;
  z-index: $z2;
  background-image: url(/images/snow-falling-mobile.gif);
  background-size: cover;
  @include media('>=tablet') {
    background-image: url(/images/snow-falling-desktop.gif);
  }
}
*/

// https://codepen.io/alvaromontoro/pen/GRNmdzB
.snow-falling {
  @include position(fixed, 0);
  pointer-events: none;
  z-index: $z2;
  .snowflake {
    --size: 1vw;
    width: var(--size);
    height: var(--size);
    background: white;
    border-radius: 50%;
    position: absolute;
    top: -5vh;
  }

  @keyframes snowfall {
    0% {
      transform: translate3d(var(--left-ini), 0, 0);
    }
    100% {
      transform: translate3d(var(--left-end), 110vh, 0);
    }
  }

  @for $i from 1 through 20 {
    .snowflake:nth-child(#{$i}) {
      --size: #{random(5) * 0.27}vw;
      --left-ini: #{random(20) - 10}vw;
      --left-end: #{random(20) - 10}vw;
      left: #{random(100)}vw;
      animation: snowfall #{10 + random(10)}s linear infinite;
      animation-delay: -#{random(10)}s;
    }
  }
}
